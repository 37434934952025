import React from 'react';
import CountUpVisibility from '../components/CountUpVisibility';
import PieChartAnimation from '../components/PieChartAnimation';

const dataMock = [
    { key: '13-17', data: 0.3 },
    { key: '18-24', data: 16.3 },
    { key: '25-34', data: 25.9 },
    { key: '35-44', data: 23.1 },
    { key: '45-54', data: 23.6 },
    { key: '55-64', data: 8.4 },
    { key: '65+', data: 2.4 },
];

const Analytics: React.FC = () => {

    return <section id='analytics' className='section'>
        <h2 className='title2 center'>Ecco i miei numeri</h2>
        <div className='container grid'>
            <div style={{ textAlign: 'center' }}>
                <a aria-label='Youtube' className='title4 url' href='https://www.youtube.com/c/nicolasagliano'>Youtube</a>
                <CountUpVisibility number={13500} className='title4' plus />
            </div>
            <div style={{ textAlign: 'center' }}>
                <a aria-label='Tiktok' className='title4 url' href='https://www.tiktok.com/@nicola_sagliano'>Tiktok</a>
                <CountUpVisibility number={30000} className='title4' plus />
            </div>
            <div style={{ textAlign: 'center' }}>
                <a aria-label='Telegram' className='title4 url' href='https://t.me/sagliaofferte'>Telegram</a>
                <CountUpVisibility number={3000} className='title4' plus />
            </div>
            <div style={{ textAlign: 'center' }}>
                <p className='title4'>Visualizzazioni Totali</p>
                <CountUpVisibility number={9500000} className='title4' plus />
            </div>
        </div>
        <PieChartAnimation data={dataMock} />
    </section>
}

export default Analytics