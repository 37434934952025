import React from 'react';
import ActivityIcon from '../components/ActivityIcon';
import { ReactComponent as Youtube } from '../icon/video-square.svg';
import { ReactComponent as Gallery } from '../icon/gallery.svg';
import { ReactComponent as Shorts } from '../icon/forward-15.svg';
import { ReactComponent as Heart } from '../icon/heart.svg';
import { ReactComponent as Crown } from '../icon/crown.svg';
import { ReactComponent as Ads } from '../icon/favorite-chart.svg';
import { Slide } from "react-awesome-reveal";

const Services: React.FC = () => {

    return <section id='services' className='section'>
        <h2 className='title2 center'>Come posso aiutarti nel tuo progetto</h2>
        <Slide triggerOnce={true} direction="up">
            <div className='container grid'>
                <ActivityIcon icon={<Youtube stroke='var(--blue)' width={50} height={50} />} title='Video Recensione' text='Creazione di un contenuto di presentazione del tuo prodotto, analizzando i suoi vantaggi e pregi rispetto alla concorrenza'></ActivityIcon>
                <ActivityIcon icon={<Gallery stroke='var(--blue)' width={50} height={50} />} title='Video Esperienziale' text="Creazione di un contenuto dinamico, dove si mostra sul campo l'esperienza nell'utilizzo del tuo prodotto "></ActivityIcon>
                <ActivityIcon icon={<Shorts stroke='var(--blue)' width={50} height={50} />} title='Pacchetto Shorts' text='Shorts, Reels, Tiktok i contenuti brevi stanno spopolando e possono essere la soluzione migliore per promuovere il tuo prodotto'></ActivityIcon>
                <ActivityIcon icon={<Heart stroke='var(--blue)' width={50} height={50} />} title='Sponsor' text="Sponsorizza il tuo prodotto tramite un placement di circa 1 minuto nella parte iniziale di un mio video su Youtube "></ActivityIcon>
                <ActivityIcon icon={<Ads stroke='var(--blue)' width={50} height={50} />} title='Advertise' text='Sfrutta una parte del mio contenuto per le tue campagne ADV sui vari social in modo da avere un doppio ritorno sul tuo investimento'></ActivityIcon>
                <ActivityIcon icon={<Crown stroke='var(--blue)' width={50} height={50} />} title='Pacchetto 100%' text='Posso offrirti molto di più di un semplice contenuto sui social contattami e troverò la soluzione migliore alla tua esigenza'></ActivityIcon>
            </div>
        </Slide >
    </section>
}

export default Services