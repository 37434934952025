import React, { useLayoutEffect } from "react";

type props = {
  embedId: string
}


const YoutubeEmbedHome: React.FC<props> = ({ embedId }) => {

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 2000) {
        setDimensions({ width: 2500, height: window.innerHeight });
        return
      }

      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return <iframe
    src={`https://www.youtube.com/embed/${embedId}?autoplay=1&mute=1&controls=0&loop=1&playlist=${embedId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded youtube"
    style={{
      position: 'absolute',
      zIndex: 0,
      width: dimensions.width + 500,
      height: (dimensions.width / 16) * 9 + 500,
      top: 0,
      left: 0,
      overflow: 'hidden',
      opacity: 1,
      userSelect: 'none',
      marginTop: -130,
      marginLeft: -394
    }}
  />
}

export default YoutubeEmbedHome;