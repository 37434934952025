import React from 'react';
import { Slide } from "react-awesome-reveal";
import CountUpVisibility from '../components/CountUpVisibility';

const AboutMe: React.FC = () => {

    return <section id='aboutme' className='section' style={{ backgroundColor: "var(--grey3)" }}>
        <h2 className='title2 center'>Scopri di più su di me</h2>
        <div className='container row'>
            <Slide triggerOnce>
                <div>
                    <h3 className='aboutTitle'>Ciao, sono <span style={{ fontWeight: 700, borderBottom: '3px solid var(--blue)' }}>Nicola Sagliano</span></h3>
                    <p className='text3' style={{ maxWidth: 700 }}>
                        Sono uno sviluppatore e analista software con una passione per le tastiere e per tutto quello che è tecnologico. Mi diverto a provare sempre nuovi gadget e prodotti per portare la mia esperienza al pubblico. La mia missione è riuscire a spiegare la tecnologia in modo semplice e comprensibile da chiunque, in fondo la tecnologia è nata per semplificare la vita e non per renderla più complicata.
                    </p>
                </div>
            </Slide>
            <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column', justifyContent: 'center', position: 'relative', top: '-10px' }}>
                <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>
                    <CountUpVisibility number={235} className='bigNumber' plus={false} />
                    <span className='dot'></span>
                </div>
                <h3 style={{ margin: 0, fontWeight: 600, fontSize: '2rem' }}>Video realizzati</h3>
            </div>
        </div>
    </section>
}

export default AboutMe