import React from 'react';
import { ReactComponent as Mail } from '../icon/mail.svg';
import { Slide } from "react-awesome-reveal";
import Social from '../components/Social';

const Contact: React.FC = () => {

    return <section id='contact' className='section' style={{ backgroundColor: "var(--blue)" }}>
        <h2 className='title2 center'>Entriamo in contatto</h2>
        <Slide triggerOnce={true} direction="up">
            <button className='button' style={{ margin: 'auto' }}><Mail width={30} height={30} />nicolasaglianobusiness@gmail.com</button>

           <Social color='var(--black)'></Social>
        </Slide>
    </section>
}

export default Contact