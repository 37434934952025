import React from 'react';
import YoutubeEmbed from '../components/YoutubeEmbed';
import { Zoom } from "react-awesome-reveal";


const MyWorks: React.FC = () => {

    return <section id='myworks' className='section' style={{ backgroundColor: "var(--grey3)" }}>
        <h2 className='title2 center'>Alcuni dei miei lavori</h2>
        <Zoom triggerOnce={true}>
            <div className='container row' style={{ gap: 50 }}>
                <YoutubeEmbed embedId="J5kAsQoIB9I" />
                <YoutubeEmbed embedId="JK6iOrnO6lY" />
                <YoutubeEmbed embedId="Zfwf_Cm2Ibw" />
                <YoutubeEmbed embedId="SRzzv2ft9YY" />
                <YoutubeEmbed embedId="zDpZIZdyrDA" />
                <YoutubeEmbed embedId="hfIKYQ6E0Rg" />
            </div>
        </Zoom>
    </section>
}

export default MyWorks