import React from 'react';
import Amazfit from '../azienda/amazfit.webp';
import Gardaland from '../azienda/gardaland.webp';
import Meridio from '../azienda/meridio.webp';
import Saphe from '../azienda/saphe.webp';
import Fiscozen from '../azienda/fiscozen.svg';
import Twinkly from '../azienda/twinkly.webp';
import Urbanista from '../azienda/urbanista.svg';
import Reolink from '../azienda/reolink.webp';
import Noctua from '../azienda/noctua.webp';
import Nanoleaf from '../azienda/nanoleaf.svg';
import MSI from '../azienda/msi.png';

const MyClients: React.FC = () => {

    return <section id='myclients' className='section'>
        <h2 className='title2 center'>Le aziende che hanno collaborato con me</h2>
        <div className='container-scrolling'>
            <img alt='Nanoleaf' className='logoAzienda' src={Nanoleaf}></img>
            <img alt='Amazfit' className='logoAzienda' src={Amazfit}></img>
            <img alt='MSI' className='logoAzienda' src={MSI}></img>
            <img alt='Noctua' className='logoAzienda' src={Noctua}></img>
            <img alt='Gardaland' className='logoAzienda' src={Gardaland}></img>
            <img alt='Meridio' className='logoAzienda' src={Meridio}></img>
            <img alt='Saphe' className='logoAzienda' src={Saphe}></img>
            <img alt='Twinkly' className='logoAzienda' src={Twinkly}></img>
            <img alt='Urbanista' className='logoAzienda' src={Urbanista}></img>
            <img alt='Fiscozen' className='logoAzienda' src={Fiscozen}></img>
            <img alt='Nanoleaf' className='logoAzienda' src={Nanoleaf}></img>
            <img alt='Amazfit' className='logoAzienda' src={Amazfit}></img>
            <img alt='MSI' className='logoAzienda' src={MSI}></img>
            <img alt='Gardaland' className='logoAzienda' src={Gardaland}></img>
            <img alt='Noctua' className='logoAzienda' src={Noctua}></img>
            <img alt='Meridio' className='logoAzienda' src={Meridio}></img>
            <img alt='Saphe' className='logoAzienda' src={Saphe}></img>
            <img alt='Twinkly' className='logoAzienda' src={Twinkly}></img>
            <img alt='Urbanista' className='logoAzienda' src={Urbanista}></img>
            <img alt='Fiscozen' className='logoAzienda' src={Fiscozen}></img>
        </div>
    </section>
}

export default MyClients