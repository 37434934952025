import './App.css';
import Header from './section/Header';
import Services from './section/Services';
import AboutMe from './section/AboutMe';
import Analytics from './section/Analytics';
import MyWorks from './section/MyWorks';
import MyClients from './section/MyClients';
import Contact from './section/Contact';
import NavBar from './components/Navbar';

function App() {
  return <>
    <NavBar />
    <Header />
    <Services />
    <AboutMe />
    <Analytics />
    <MyWorks />
    <MyClients />
    <Contact />
  </>

}

export default App;
