import React from 'react';
import ImgRounded from '../components/ImgRounded';
import TypedText from '../components/TypedText';
import YoutubeEmbedHome from '../components/YoutubeEmbedHome';
import { ReactComponent as Arrow } from '../icon/arrow-down.svg';
import { Zoom } from "react-awesome-reveal";
import { Slide } from "react-awesome-reveal";


const Header: React.FC = () => {
    return <section id='home' className='fullscreen bg-primary'>
        <div className='wrapperYT'><YoutubeEmbedHome embedId='WjjryR698ok'></YoutubeEmbedHome></div>
        <div id='mask' className='mask'></div>
        <div className='container'>
            <div className='row'>
                <Zoom triggerOnce={true}>
                    <ImgRounded />
                </Zoom>
                <Slide triggerOnce={true} direction={'right'} style={{ display: 'flex' }}>
                    <div className='centerVert'>
                        <h1 className='title'>
                            Ciao, sono Nicola Sagliano
                        </h1>
                        <TypedText text={['INFLUENCER', 'DEVELOPER', 'ANALYST', 'VIDEOMAKER']}></TypedText>
                        <a aria-label='MyWorks' className='button' style={{ maxWidth: 400, marginTop: 10 }} href='#myworks'>I miei lavori</a>
                    </div>
                </Slide>
            </div>
            <a aria-label='Services' href='#services'><Arrow stroke='var(--black)' width={50} height={50} className='arrow' /></a>
        </div>
    </section>
}

export default Header