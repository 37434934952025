import Typewriter from 'typewriter-effect';

type props = {
    text: string[]
}

const TypedText: React.FC<props> = ({text}) => {
    return <Typewriter
        options={{
            strings: text,
            autoStart: true,
            loop: true,
        }}
    />
}

export default TypedText

