import React, { ReactNode } from 'react'

type props = {
    icon: ReactNode,
    title: string,
    text: string
}

const ActivityIcon: React.FC<props> = ({ icon, title, text }) => {

    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 10, maxWidth: 300 }}>
        {icon}
        <h3 className='title3'>{title}</h3>
        <span className='text'>{text}</span>
    </div>
}

export default ActivityIcon