import { InView } from 'react-intersection-observer';
import { CountUp } from "use-count-up"

type props = {
    number: number,
    className: string,
    plus: boolean

}
const CountUpVisibility: React.FC<props> = ({ number, className, plus }) => {
    return <InView triggerOnce>
        {({ inView, ref, entry }) => (
            <p ref={ref} className={className}>
                {inView ? <CountUp isCounting duration={1.5} end={number} easing={"easeOutCubic"} formatter={(v) => v.toFixed(0) + (plus ? "+" : "")} /> : 0}
            </p>
        )}
    </InView>
}

export default CountUpVisibility