import React, { useEffect, useState } from 'react';
import { ReactComponent as Logo } from "../icon/logo.svg";
import { ReactComponent as Menu } from "../icon/menu.svg";
import { ReactComponent as Close } from "../icon/close-square.svg";
import Social from './Social';

const NavBar: React.FC = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const [scroll, setScroll] = useState(false);

    let listenScrollEvent = () => {
        if (window.scrollY > 50) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent)

        return () => {
            window.removeEventListener('scroll', listenScrollEvent)
        }
    }, [listenScrollEvent])

    return <header className='sticky'>
        <nav className={scroll ? 'navbar navbarScroll' : 'navbar'}>
            <div className='navbarContainer'>
                <a style={{ display: 'flex' }} aria-label="Home" href="/">
                    <Logo className="logo" width={50} height={50} />
                </a>
                <div className={click ? 'navbarOverlay navbarOpen' : 'navbarOverlay'}>
                    <div className='navbarCenter'>
                        <ul className={click ? "navbar-nav active" : "navbar-nav"}>
                            <a aria-label='Home' className='navItem' href='#home' onClick={handleClick}>Home</a>
                            <a aria-label='Services' className='navItem' href='#services' onClick={handleClick}>Cosa posso fare</a>
                            <a aria-label='AboutMe' className='navItem' href='#aboutme' onClick={handleClick}>Chi sono</a>
                            <a aria-label='Analytics' className='navItem' href='#analytics' onClick={handleClick}>I miei numeri</a>
                            <a aria-label='MyClients' className='navItem' href='#myclients' onClick={handleClick}>I miei clienti</a>
                            <a aria-label='Contact' className='navItem' href='#contact' onClick={handleClick}>Contattami</a>
                        </ul>
                        <Social color='var(--white)'></Social>
                    </div>
                </div>


                <button aria-label='menu' className='clear' onClick={handleClick}>
                    {click ? (
                        <Close className="logo" width={50} height={50} style={{ stroke: 'var(--white)', zIndex: 2000 }} />
                    ) : (
                        <Menu className="logo" width={50} height={50} style={{ stroke: 'var(--black)' }} />
                    )}
                </button>
            </div>
        </nav>
    </header>
}

export default NavBar