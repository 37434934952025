import InView from "react-intersection-observer"
import { ChartTooltip, PieArc, PieArcLabel, PieArcSeries, PieChart } from "reaviz"

type props = {
    data: { key: string, data: number }[]
}

const PieChartAnimation: React.FC<props> = ({ data }) => {
    return <InView triggerOnce delay={500}>
        {({ inView, ref, entry }) => (<div ref={ref} className="containerAge">
            <div style={{
                position: 'absolute', display: 'flex',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
            }}>
                {inView ? <PieChart
                    data={data}
                    series={
                        <PieArcSeries
                            cornerRadius={4}
                            padAngle={0.02}
                            padRadius={400}
                            doughnut={true}
                            colorScheme={'cybertron'}
                            label={
                                <PieArcLabel
                                    fontSize={16}
                                    fontFill={"var(--black)"}
                                />
                            }
                            arc={
                                <PieArc
                                    tooltip={
                                        <ChartTooltip
                                            className={"tooltip"}

                                        />
                                    }
                                />
                            }
                        />
                    }
                /> : <span></span>}
            </div>

            <h2 className="age">
                Età del pubblico
            </h2>
        </div>
        )}
    </InView>
}

export default PieChartAnimation