import { ReactComponent as Facebook } from '../icon/facebook.svg';
import { ReactComponent as Youtube } from '../icon/youtube.svg';
import { ReactComponent as Telegram } from '../icon/telegram.svg';
import { ReactComponent as Tiktok } from '../icon/tiktok.svg';
import { ReactComponent as Instagram } from '../icon/instagram.svg';
import { ReactComponent as Mail } from '../icon/mail.svg';
import { ReactComponent as Linkedin } from '../icon/linkedin.svg';
import { ReactComponent as Global } from '../icon/global.svg';

type props = {
    color: string
}

const Social: React.FC<props> = ({ color }) => {

    return <div className='row' style={{ marginTop: '5rem', gap: '30px' }}>
        <a aria-label='Youtube' style={{ color: color }} href='https://www.youtube.com/c/nicolasagliano'><Youtube width={30} height={30} /></a>
        <a aria-label='Tiktok' style={{ color: color }} href='https://www.tiktok.com/@nicola_sagliano'><Tiktok width={30} height={30} /></a>
        <a aria-label='Telegram' style={{ color: color }} href='https://t.me/sagliaofferte'><Telegram width={30} height={30} /></a>
        <a aria-label='Instagram' style={{ color: color }} href='https://www.instagram.com/nicola_sagliano/'><Instagram width={30} height={30} /></a>
        <a aria-label='Facebook' style={{ color: color }} href='https://www.facebook.com/nicolasaglianotech'><Facebook width={30} height={30} /></a>
        <a aria-label='Mail' style={{ color: color }} href='mailto:nicolasaglianobusiness@gmail.com' target="_top"><Mail width={30} height={30} /></a>
        <a aria-label='Linkedin' style={{ color: color }} href='https://www.linkedin.com/in/nicola-sagliano/'><Linkedin width={30} height={30} /></a>
        <a aria-label='Website' style={{ color: color }} href='https://nicolasagliano.com/'><Global width={30} height={30} /></a>
    </div>
}

export default Social